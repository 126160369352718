import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Avatar from './Avatar'
// import Avatar from '../Avatar'
// import { GLOBALTYPES } from '../../redux/actions/globalTypes'
// import { addMessage } from '../../redux/actions/messageAction'
import rt_24fyzljs8vp4h from '../assets/audio/rt_24fyzljs8vp4h.mp3'
import { MdCall, MdCallEnd } from "react-icons/md";
import { FaVideo } from "react-icons/fa6";
import './call_modal.css'
import { setCall } from '../redux/userSlice';
import toast from 'react-hot-toast';


const CallModal = () => {
    const user = useSelector(state => state.user)
    const call = useSelector(state => state.user.call)
    const socketConnection = useSelector(state => state?.user?.socketConnection)
    const peer = useSelector(state => state.user.peer)
    // const { call, auth, peer, socket, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [hours, setHours] = useState(0)
    const [mins, setMins] = useState(0)
    const [second, setSecond] = useState(0)
    const [total, setTotal] = useState(0)

    const [answer, setAnswer] = useState(false)
    const youVideo = useRef()
    const otherVideo = useRef()
    const [tracks, setTracks] = useState(null)
    const [newCall, setNewCall] = useState(null)

    // Set Time
    useEffect(() => {
        const setTime = () => {
            setTotal(t => t + 1)
            setTimeout(setTime, 1000)
        }
        setTime()

        return () => setTotal(0)
    },[])

    useEffect(() => {
        setSecond(total%60)
        setMins(parseInt(total/60))
        setHours(parseInt(total/3600))
    },[total])


    // End Call
    const addCallMessage = useCallback((call, times, disconnect) => {
        if(call.recipient !== user._id || disconnect){

            if(socketConnection){
                socketConnection.emit('new message',{
                  sender : call.sender,
                  receiver : call.recipient,
                  text : '',
                  imageUrl : '',
                  videoUrl : '',
                  msgByUserId : call.sender,
                  call: {video: call.video, times},
                })
              }
        }
    },[user, dispatch, socketConnection])

    const handleEndCall = () => {
        tracks && tracks.forEach(track => track.stop())
        if(newCall) newCall.close()
        let times = answer ? total : 0
        socketConnection.emit('endCall', {...call, times})
        
        addCallMessage(call, times)
        dispatch(setCall(null))
    }

    useEffect(() => {
            if(answer){
            setTotal(0)
        }else{
            const timer = setTimeout(() => {
                socketConnection.emit('endCall', {...call, times: 0})
                addCallMessage(call, 0)
                dispatch(setCall(null))
            }, 15000)
    
            return () => clearTimeout(timer)
        }
        
    },[dispatch, answer, call, socketConnection, addCallMessage])


    useEffect(() => {
        socketConnection.on('endCallToClient', data => {
            tracks && tracks.forEach(track => track.stop())
            if(newCall) newCall.close()
            addCallMessage(data, data.times)
            dispatch(setCall(null))
        })

        return () => socketConnection.off('endCallToClient')

    },[socketConnection, dispatch, tracks, addCallMessage, newCall])


    // // Stream Media
    const openStream = (video) => {
        const config = { audio: true, video }
        return navigator.mediaDevices.getUserMedia(config)
    }


    const playStream = (tag, stream) => {
        let video = tag;
        video.srcObject = stream;
        video.play()
    }

    // // Answer Call
    const handleAnswer = () => {
        openStream(call.video).then(stream => {
            playStream(youVideo.current, stream)
            const track = stream.getTracks()
            setTracks(track)
            
            const newCall = peer.call(call.peerId, stream);
            newCall.on('stream', function(remoteStream) {
                playStream(otherVideo.current, remoteStream)
            });
            setAnswer(true)
            setNewCall(newCall)
        })
    }

    useEffect(() => {
        peer.on('call', newCall => {
            openStream(call.video).then(stream => {
                if(youVideo.current){
                    playStream(youVideo.current, stream)
                }
                const track = stream.getTracks()
                setTracks(track)
                
                newCall.answer(stream)
                newCall.on('stream', function(remoteStream) {
                    if(otherVideo.current){
                        playStream(otherVideo.current, remoteStream)
                    }
                });
                setAnswer(true) 
                setNewCall(newCall)
            })
        })
        return () => peer.removeListener('call')
    },[peer, call.video])

    // // Disconnect
    useEffect(() => {
        socketConnection?.on('callerDisconnect', () => {
            tracks && tracks.forEach(track => track.stop())
            if(newCall) newCall.close()
            let times = answer ? total : 0
            addCallMessage(call, times, true)

            // dispatch({type: GLOBALTYPES.CALL, payload: null })
            dispatch(setCall(null))

            // dispatch({
            //     type: GLOBALTYPES.ALERT, 
            //     payload: {error: `The ${call.username} disconnect`} 
            // })
            toast.error(`The ${call.username} disconnect`)
        })

        return () => socketConnection?.off('callerDisconnect')
    },[socketConnection, tracks, dispatch, call, addCallMessage, answer, total, newCall])

    // Play - Pause Audio
    const playAudio = (newAudio) => {
        newAudio.play()
    }

    const pauseAudio = (newAudio) => {
        newAudio.pause()
        newAudio.currentTime = 0
    }

    useEffect(() => {
        let newAudio = new Audio(rt_24fyzljs8vp4h)
        if(answer){
            pauseAudio(newAudio)
        }else{
            playAudio(newAudio)
        }

        return () => pauseAudio(newAudio)
    },[answer])


    return (
        <div className="call_modal">
            <div className="call_box">
            <div className="text-center" style={{padding: '40px 0'}} >
            
                      <Avatar
                        width={100}
                        height={100}
                        imageUrl={call?.profile_pic}
                        name={call?.name}
                        userId={call?._id}
                      />

                    <h4>{call.username}</h4>
                    {/* <h6>{call.fullname}</h6> */}

                    {
                        answer 
                        ? <div>
                            <span>{ hours.toString().length < 2 ? '0' + hours : hours }</span>
                            <span>:</span>
                            <span>{ mins.toString().length < 2 ? '0' + mins : mins }</span>
                            <span>:</span>
                            <span>{ second.toString().length < 2 ? '0' + second : second }</span>
                        </div>
                        : <div>
                            {
                                call.video
                                ? <span>calling video...</span>
                                : <span>calling audio...</span>
                            }
                        </div>
                    }
                    </div>

                    {
                    !answer && 
                    <div className="timer">
                        <small>{ mins.toString().length < 2 ? '0' + mins : mins }</small>
                        <small>:</small>
                        <small>{ second.toString().length < 2 ? '0' + second : second }</small>
                    </div>
                }
                
                    

                    <div className="call_menu">
                    <button  class="text-red-500 hover:text-red-700"
                    onClick={handleEndCall}
                    >
                       <MdCallEnd size={20}/>
                    </button>
                    
                    {
                        (call.recipient === user._id && !answer) &&
                        <>
                            {
                                call.video
                                ? <button class="text-green-500 hover:text-green-700"
                                onClick={handleAnswer}
                                >
                                    <FaVideo size={20}/>
                                </button>
                                : <button class="text-green-500 hover:text-green-700"
                                onClick={handleAnswer}
                                >
                                    <MdCall size={20}/>
                                </button>
                            }
                        </>
                       
                    }
                    </div>

                    <div className="show_video" style={{
                opacity: (answer && call.video) ? '1' : '0',
                // filter: theme ? 'invert(1)' : 'invert(0)'
            }} >

                <video ref={youVideo} className="you_video" playsInline muted />
                <video ref={otherVideo} className="other_video" playsInline />

                <div className="time_video">
                    <span>{ hours.toString().length < 2 ? '0' + hours : hours }</span>
                    <span>:</span>
                    <span>{ mins.toString().length < 2 ? '0' + mins : mins }</span>
                    <span>:</span>
                    <span>{ second.toString().length < 2 ? '0' + second : second }</span>
                </div>

                <button  class="text-red-500 hover:text-red-700"
                    onClick={handleEndCall}
                    >
                       <MdCallEnd size={20}/>
                    </button>

            </div>
        </div>
            {/* <div className="call_box" style={{
                display: (answer && call.video) ? 'none' : 'flex'
            }} >

                <div className="text-center" style={{padding: '40px 0'}} >
                    <Avatar src={call.avatar} size="supper-avatar" />
                    <h4>{call.username}</h4>
                    <h6>{call.fullname}</h6>

                    {
                        answer 
                        ? <div>
                            <span>{ hours.toString().length < 2 ? '0' + hours : hours }</span>
                            <span>:</span>
                            <span>{ mins.toString().length < 2 ? '0' + mins : mins }</span>
                            <span>:</span>
                            <span>{ second.toString().length < 2 ? '0' + second : second }</span>
                        </div>
                        : <div>
                            {
                                call.video
                                ? <span>calling video...</span>
                                : <span>calling audio...</span>
                            }
                        </div>
                    }
                    
                </div>
                
                {
                    !answer && 
                    <div className="timer">
                        <small>{ mins.toString().length < 2 ? '0' + mins : mins }</small>
                        <small>:</small>
                        <small>{ second.toString().length < 2 ? '0' + second : second }</small>
                    </div>
                }
                

                <div className="call_menu">
                    <button className="material-icons text-danger"
                    onClick={handleEndCall}>
                        call_end
                    </button>
                    
                    {
                        (call.recipient === auth.user._id && !answer) &&
                        <>
                            {
                                call.video
                                ? <button className="material-icons text-success"
                                onClick={handleAnswer}>
                                    videocam
                                </button>
                                : <button className="material-icons text-success"
                                onClick={handleAnswer}>
                                    call
                                </button>
                            }
                        </>
                    }
                    
                </div>
                
            </div>

            <div className="show_video" style={{
                opacity: (answer && call.video) ? '1' : '0',
                filter: theme ? 'invert(1)' : 'invert(0)'
            }} >

                <video ref={youVideo} className="you_video" playsInline muted />
                <video ref={otherVideo} className="other_video" playsInline />

                <div className="time_video">
                    <span>{ hours.toString().length < 2 ? '0' + hours : hours }</span>
                    <span>:</span>
                    <span>{ mins.toString().length < 2 ? '0' + mins : mins }</span>
                    <span>:</span>
                    <span>{ second.toString().length < 2 ? '0' + second : second }</span>
                </div>

                <button className="material-icons text-danger end_call"
                onClick={handleEndCall}>
                    call_end
                </button>

            </div> */}

        </div>
    )
}

export default CallModal
