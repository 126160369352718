import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCall } from './redux/userSlice'
import toast from 'react-hot-toast';

const SocketClient = () => {
    const socketConnection = useSelector(state => state?.user?.socketConnection)
    const call = useSelector(state => state?.user?.call)
    const dispatch = useDispatch()
    
    useEffect(() => {
        console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRr")
        socketConnection?.on('callUserToClient',(data)=>{
            dispatch(setCall(data))
        })

        return () => socketConnection?.off('callUserToClient');

    },[socketConnection, dispatch])

    useEffect(() => {
        socketConnection?.on('userBusy', (data)=>{
            // dispatch(setCall(null))
            toast.error(`${call.username} is busy!`)
            
        })

        return () => socketConnection?.off('userBusy');
    },[socketConnection, dispatch, call])

}

export default SocketClient