import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Avatar from './Avatar'
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa6";
import uploadFile from '../helpers/uploadFile';
import { IoClose } from "react-icons/io5";
import Loading from './Loading';
import backgroundImage from '../assets/wallapaper.jpeg'
import { IoMdSend } from "react-icons/io";
import moment from 'moment';
import { MdCall } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { setCall } from '../redux/userSlice';
import { IoVideocamOff } from "react-icons/io5";
import { FaPhoneSlash } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import Times from './Times';

const MsgDisplay = ({msg, user, socketConnection, handleDeleteMessage}) => {
    const [isHovered, setIsHovered] = useState(false); 
    
    const handleDeleteMessages = () => {
        if (window.confirm('Do you want to delete?')) {
            socketConnection?.emit('deleteMessages', msg);
        }
        // setAllMessage={setAllMessage}
        // setAllMessage(allMessage.filter((m) => m !== msg));
        handleDeleteMessage(msg);
      };

  return (
           <div className={`p-1 py-1 rounded w-fit max-w-[280px] md:max-w-sm lg:max-w-md ${user._id === msg?.msgByUserId ? "ml-auto bg-teal-100" : "bg-white"}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onTouchStart={() => setIsHovered(true)}
                onTouchEnd={() => setIsHovered(false)} >
                 {isHovered && user._id === msg?.msgByUserId  && (
                  <div className='pb-2'  onClick={handleDeleteMessages}>
                     <MdCancel size={20} color='red'/>
                </div>
                   )}
                <div>
                  <div className='w-full relative'>
                    {
                      msg?.imageUrl && (
                        <img
                          src={msg?.imageUrl}
                          className='w-full h-full object-scale-down'
                        />
                      )
                    }
                    {
                      msg?.videoUrl && (
                        <video
                          src={msg.videoUrl}
                          className='w-full h-full object-scale-down'
                          controls
                        />
                      )
                    }
                    {msg.call && (
                      <button
                        className="py-3"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#eee', borderRadius: '10px' }}
                      >
                        <span
                          className="px-3"
                          style={{
                            fontSize: '2.5rem',
                            color: msg.call.times === 0 ? 'crimson' : 'green',
                          }}
                        >
                          {msg.call.times === 0
                            ? msg.call.video
                              ? <IoVideocamOff />
                              : <FaPhoneSlash />
                            : msg.call.video
                              ? <FaVideo />
                              : <MdCall />}
                        </span>

                        <div className="text-left">
                          <h6>{msg.call.video ? 'Video Call' : 'Audio Call'}</h6>
                          <small>
                            {msg.call.times > 0 ? (
                              <Times total={msg.call.times} />
                            ) : (
                              new Date(msg.createdAt).toLocaleTimeString()
                            )}
                          </small>
                        </div>
                      </button>
                    )}
                  </div>
                  <p className='px-2'>{msg.text}</p>
                  {/* <p className='text-xs ml-auto w-fit'>{moment(msg.createdAt).format('hh:mm')}</p> */}
                  {isHovered &&<p className='text-xs ml-auto w-fit'>{moment(msg.createdAt).fromNow()}</p>}
                  {/* <div className='flex'>
                           <p className='text-xs ml-auto w-fit'>{moment(msg.createdAt).fromNow()}</p>
                           { user._id === msg?.msgByUserId && 
                            <div>
                            { msg.seen? <span>a</span>:<span>n</span>}
                            </div>}
                           </div> */}
                </div>
                </div>
              )
};

export default MsgDisplay;
